<template>
  <div style="pointer-events: none">
    <div class="text-shadow">
      拍摄时间：{{ $moment(time).format("YYYY-MM-DD HH:mm") }}
    </div>
    <div v-if="times && (lat != 0 || lng != 0)" class="text-shadow">
      日出时间：{{ $moment(times.sunrise).format("YYYY-MM-DD HH:mm") }}
    </div>
    <div v-if="times && (lat != 0 || lng != 0)" class="text-shadow">
      日落时间：{{ $moment(times.sunset).format("YYYY-MM-DD HH:mm") }}
    </div>
    <div class="text-shadow">朝向：{{ view.Direction }}</div>
    <div class="text-shadow ruler" ref="ruler">
      <div class="scale" v-for="m in scales" :style="m.style">{{ m.text }}</div>
    </div>
    <!--<div>
      <svg width="110" height="110"
           xmlns="http://www.w3.org/2000/svg">
        <path stroke="rgb(224,224,160)" stroke-width="1" stroke-opacity="0.5" fill="rgb(127,95,63)" fill-opacity="0.5" :d="svgd" style="pointer-events: auto; cursor: pointer; transform: scale(1, 1);"></path>
      </svg>
    </div>-->
    <div class="toolbarBox">
      <div class="text-shadow">
        <div v-if="type == null">此功能用于确定全景正北方向</div>
        <div v-if="type == 'sun'">请点击太阳所在位置</div>
        <div v-if="type == 'moon'">请点击月亮所在位置</div>
      </div>
      <div
        class="PanoMapBox"
        :class="mapClass"
        :style="mapStyle"
        v-if="mapable"
        style="pointer-events: auto"
      >
        <div class="mapSize text-shadow" @click="changeMap()">
          <!--<i v-if="mapsize==0" class="fas fa-expand-arrows-alt"></i>
          <i v-if="mapsize==1" class="fas fa-compress-arrows-alt"></i>-->
          <i v-if="mapsize == 0" class="fas fa-expand"></i>
          <i v-if="mapsize == 1" class="fas fa-compress"></i>
        </div>
        <PanoMap
          ref="PanoMap"
          zoom="max"
          :ToolBars="mapToolBars"
          :ToolBarsConfig="{
            zoom: { position: 'topleft' },
            scale: { position: 'bottomleft' },
            layerSelect: { style: { right: 'unset', left: '10px' } },
            layersControl: { position: 'topleft' },
          }"
          :currentItem="pano"
          :currentPanoView="view"
          :currentLoc="{ lng: pano.gpsLng, lat: pano.gpsLat }"
          :mapCenter="{ lng: pano.gpsLng, lat: pano.gpsLat }"
        ></PanoMap>
      </div>
      <div v-if="editPane" style="pointer-events: auto">
        <div
          v-if="pano.gpsImgDirection != null"
          style="width: 200px; pointer-events: auto"
        >
          <NumberButton
            v-model="pano.gpsImgDirection"
            @change="ViewChange()"
            :defaultValue="0"
            :wheelRatio="0.01"
            :moveRatio="1"
            step="1"
          ></NumberButton>
        </div>
      </div>
      <div class="EditToolbar" style="pointer-events: auto">
        <div class="space"></div>
        <button class="btn toorbarBtn back" style="" @click="$emit('back')">
          <div><i class="fas fa-chevron-left"></i></div>
        </button>
        <button
          v-if="lat != 0 || lng != 0"
          class="btn toorbarBtn"
          :class="{ active: type == 'sun' }"
          @click="type = 'sun'"
        >
          <div><i class="fas fa-sun"></i></div>
          <div>太阳</div>
        </button>
        <button
          v-if="lat != 0 || lng != 0"
          class="btn toorbarBtn"
          :class="{ active: type == 'moon' }"
          @click="type = 'moon'"
        >
          <div><i class="fas fa-moon"></i></div>
          <div>月亮</div>
        </button>
        <button
          v-if="lat != 0 || lng != 0"
          class="btn toorbarBtn"
          :class="{ active: editPane }"
          @click="editPaneClick"
        >
          <div><i class="fas fa-sliders-h"></i></div>
          <div>编辑</div>
        </button>
        <button
          v-if="lat != 0 || lng != 0"
          class="btn toorbarBtn"
          :class="{ active: mapable }"
          @click="mapable = !mapable"
        >
          <div><i class="fas fa-map-marked-alt"></i></div>
          <div>地图</div>
        </button>
        <button
          v-if="editPointAble"
          class="btn toorbarBtn"
          @click="$refs.editPoint.showModal()"
        >
          <div><i class="fas fa-map-marked-alt"></i></div>
          <div>定位</div>
        </button>
        <div class="space"></div>
      </div>
    </div>
    <div style="pointer-events: auto">
      <MapSelectedPointModal
        ref="editPoint"
        :markerPoint="{ lat: lat, lng: lng }"
        @submit="setPoint"
        zoom="max"
        :ToolBars="true"
        :ToolBarsConfig="{
          zoom: { position: 'topleft' },
          scale: { position: 'bottomleft' },
          layerSelect: { style: { right: 'unset', left: '10px' } },
          layersControl: { position: 'topleft' },
        }"
        :currentItem="pano"
        :currentPanoView="view"
        :currentLoc="
          pano.gpsLng == 0 && pano.gpsLat == 0
            ? undefined
            : { lng: pano.gpsLng, lat: pano.gpsLat }
        "
        :mapCenter="{ lng: pano.gpsLng, lat: pano.gpsLat }"
      ></MapSelectedPointModal>
    </div>
  </div>
</template>
<script>
import suncalc from "suncalc";
import PanoMap from "../../Map/PanoLeafletMap";
import NumberButton from "../../NumberButton";
import MapSelectedPointModal from "../../Map/MapSelectedPointModal-PanoLeaflet";

export default {
  components: {
    PanoMap,
    NumberButton,
    MapSelectedPointModal,
  },
  props: {
    config: {
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      lat: null,
      lng: null,
      time: null,
      times: null,
      mapable: false,
      mapsize: 0,
      mapToolBars: false,
      mapClass: "sm",
      mapStyle: {},
      editPane: false,
      editPointAble: false,
      //gpsImgDirection: null,
      //get gpsImgDirection() {
      //  return this.pano?.gpsImgDirection ?? this.pano?.exif?.gpsImgDirection
      //},
      //set gpsImgDirection(val) {
      //  this.pano.gpsImgDirection = val
      //},
      type: null,
      view: {},
      scales: [],
      svgd: "",
    };
  },
  watch: {},
  inject: {
    publicData: {
      default: {},
    },
    getComonent: {
      default: {},
    },
    getFunc: {},
  },
  computed: {
    pano() {
      return this.publicData.pano || {};
    },
  },
  created() {},
  mounted() {
    this.$emit("ready", {
      vm: this,
      events: {},
      funcs: {
        PanoClick: this.PanoClick,
        ViewChange: this.ViewChange,
      },
    });
    this.lat = this.pano?.exif?.gpsLat || this.pano.gpsLat;
    this.lng = this.pano?.exif?.gpsLng || this.pano.gpsLng;
    this.time = new Date(this.pano?.exif?.dateTimeOriginal);
    this.editPointAble = !this.pano?.exif?.gpsLat || !this.pano?.exif?.gpsLat;
    this.mapable = this.lat != 0 || this.lng != 0;
    this.editPane = this.lat != 0 || this.lng != 0;
    if (this.lat == 0 && this.lng == 0) {
      setTimeout(() => {
        this.$refs.editPoint.showModal();
      }, 1000);
    }
    //this.gpsImgDirection = this.pano?.exif?.gpsImgDirection
    //this.pano.gpsImgDirection ??= this.pano?.exif?.gpsImgDirection ??0
    if (this.time) {
      this.times = suncalc.getTimes(this.time, this.lat, this.lng);
      //for (var i in this.times) {
      //  this.times[i] = new Date(his.times[i])
      //}
      //switch (true) {
      //  case new Date(this.times.nightEnd) < this.time && new Date(this.times.night) > this.time:
      //    this.type = 'sun'
      //    break;
      //  default:
      //    this.type = 'moon'
      //    break;
      //}
    }
    this.ViewChange();
  },
  destroyed() {},
  methods: {
    setPoint(p) {
      this.pano.gpsLat = p.lat;
      this.pano.gpsLng = p.lng;
      this.lat = p.lat;
      this.lng = p.lng;

      this.times = suncalc.getTimes(this.time, this.lat, this.lng);
      this.mapable = false;
      setTimeout(() => {
        this.mapable = true;
      }, 100);
    },
    editPaneClick() {
      this.editPane = !this.editPane;
      if (this.editPane) {
        this.pano.gpsImgDirection ??= 0;
      }
    },
    PanoClick(e) {
      var d;
      switch (this.type) {
        case "sun":
          d = suncalc.getPosition(
            /*Date*/ this.time,
            /*Number*/ this.lat,
            /*Number*/ this.lng
          );
          break;
        case "moon":
          d = suncalc.getMoonPosition(
            /*Date*/ this.time,
            /*Number*/ this.lat,
            /*Number*/ this.lng
          );
          break;
        default:
          return;
      }
      d.tov = (-d.altitude * 180) / Math.PI;
      d.toh = (d.azimuth * 180) / Math.PI + 180;
      while (d.toh > 360) {
        d.toh -= 360;
      }
      console.log(d, e);
      var ig = -d.toh + e.toh;
      ig = this.math360(ig);
      console.log(d.tov - e.tov, d.toh, e.toh, ig);
      this.pano.gpsImgDirection = Number(ig.toFixed(2));
      this.type = null;
      this.renewMarks();
    },
    ViewChange() {
      var v = this.getFunc({ target: "FullPano", name: "getView" })();
      this.view = {
        fov: Number(Number(v.fov).toFixed(1)),
        hLookAt: Number(this.math360(Number(v.hlookat)).toFixed(2)),
        vLookAt: Number(Number(v.vlookat).toFixed(2)),
      };
      this.view.Direction = Number(
        this.math360(this.view.hLookAt - this.pano.gpsImgDirection).toFixed(2)
      );
      this.renewMarks();
    },
    renewMarks() {
      var scales = [];
      var hl = this.math360(Number(this.view.hLookAt)) - (this.pano.gpsImgDirection ?? 0);
      var r = this.view.fov / this.$refs.ruler.offsetWidth;
      var s = hl - this.view.fov / 2;
      var e = hl + this.view.fov / 2;
      //this.getd(55, 55, 50, s, e)
      scales.push({
        style: {
          left: `${(this.math360(0, hl) - s) / r}px`,
        },
        text: "北",
      });
      scales.push({
        style: {
          left: `${(this.math360(-45, hl) - s) / r}px`,
        },
        text: "西北",
      });
      scales.push({
        style: {
          left: `${(this.math360(-90, hl) - s) / r}px`,
        },
        text: "西",
      });
      scales.push({
        style: {
          left: `${(this.math360(45, hl) - s) / r}px`,
        },
        text: "东北",
      });
      scales.push({
        style: {
          left: `${(this.math360(90, hl) - s) / r}px`,
        },
        text: "东",
      });
      scales.push({
        style: {
          left: `${(this.math360(135, hl) - s) / r}px`,
        },
        text: "东南",
      });
      scales.push({
        style: {
          left: `${(this.math360(180, hl) - s) / r}px`,
        },
        text: "南",
      });
      scales.push({
        style: {
          left: `${(this.math360(-135, hl) - s) / r}px`,
        },
        text: "西南",
      });
      this.scales = scales;
    },
    math360(i, n) {
      //n ??= 180
      if (n == undefined) {
        n = 180;
      }
      while (i < n - 180 || i >= n + 180) {
        i = i > n ? i - 360 : i + 360;
      }
      return i;
    },
    //getd(d, b, k, a, c) {
    //  return this.svgd = this.drawpie(d, b, k, a, c)
    //},
    //
    //  drawpie(//绘制扇形svg路径d
    //    /*x轴坐标*/  x,
    //    /*y轴坐标*/ y,
    //    /*半径*/ r,
    //    /*起始角度*/ sa,
    //    /*终点角度*/ ea
    //  ) {
    //    var g, f;
    //    sa > ea && (g = ea, ea = sa, sa = g);
    //    sa = sa * Math.PI / 180;
    //    ea = ea * Math.PI / 180;
    //    f = ea - sa;
    //    g = (sa + ea) / 2;
    //    var h = f > Math.PI ? 1 : 0;
    //    f >= 2 * Math.PI && (f = 2 * Math.PI - .01);
    //    sa = g - f / 2; ea = g + f / 2;
    //    g = x + r * Math.sin(sa);
    //    sa = y - r * Math.cos(sa);
    //    f = x + r * Math.sin(ea);
    //    ea = y - r * Math.cos(ea);
    //    return `M ${x},${y} L  ${g},${sa} A  ${r},${r} 0 ${h} 1 ${f},${ea} Z`
    //    //return "M " + x + "," + y + " L " + g + "," + sa + " A " + r + "," + r + " 0 " + h + " 1 " + f + "," + ea + " Z"
    //  }
    changeMap() {
      this.mapsize++;
      if (this.mapsize > 1) {
        this.mapsize = 0;
      }
      switch (this.mapsize) {
        case 0:
          this.mapToolBars = false;
          this.mapClass = "sm";
          break;
        case 1:
          this.mapToolBars = true;
          this.mapClass = "lg";
          break;
        default:
      }
      setTimeout(() => {
        this.$refs.PanoMap.invalidateSize();
      });
    },
  },
};
</script>
<style scoped>
.ruler {
  position: relative;
}

.scale {
  position: absolute;
  white-space: nowrap;
}

.toolbarBox {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
}

.PanoMapBox {
  position: relative;
  opacity: 1;
}

.PanoMapBox.sm {
  width: 200px;
  height: 200px;
}

.PanoMapBox.lg {
  width: 100%;
  height: 300px;
}

.mapSize {
  position: absolute;
  top: 5px;
  right: 5px;
  line-height: 0;
  z-index: 1200;
  cursor: pointer;
}
</style>
