<template>
  <SimpleModal ref="selectMapPoint" @Show="showMap=true" @Hide="showMap=false" :zIndex="1040" boxClass="p-0">
    <div style="height:calc(100vh - 30px);width:calc(100vw - 25px);overflow:hidden;position:relative;">
      <template v-if="showMap">
        <MapSelectedPoint :mapConfig="mapConfig" @submit="submit" :markerPoint="markerPoint" :citycode="citycode"  :ToolBarsConfig="ToolBarsConfig" :currentItem="currentItem"  :currentLoc="currentLoc" :currentPanoView="currentPanoView" :currentItemConfig="currentItemConfig"
                :ToolBars="ToolBars" :baseMaps="baseMaps" :zoom="zoom" :autoZoom="autoZoom" :layers="layers" :layer="layer" :url="url" :params="params">
        </MapSelectedPoint>
      </template>
      <div class="text-shadow" style="position:absolute;top:0;right:0;padding:5px;z-index: 1000;cursor:pointer;" @click="$refs.selectMapPoint.show=false">
        <i class="fa fa-times"></i>
      </div>
    </div>
  </SimpleModal>
</template>
<script>
  import MapSelectedPoint from './MapSelectedPoint-PanoLeaflet'
  import SimpleModal from '../SimpleModal'
  export default {
    components: {
      SimpleModal,
      MapSelectedPoint,
    },
    props: {
      mapConfig: {},
      markerPoint: Object,
      citycode: Number,
      ToolBarsConfig: {},
      currentItem: {},
      currentLoc: {},
      currentPanoView: {},
      currentItemConfig: {},
      ToolBars: {},
      baseMaps: {},
      zoom: {},
      autoZoom: {},
      layers: {},
      layer: {},
      url: {},
      params: {},

    },
    data: () => {
      return {
        showMap: false,
      }
    },
    methods: {
      submit(e) {
        this.$emit('submit', e)
        this.$refs.selectMapPoint.show = false
      },
      showModal() {
        this.$refs.selectMapPoint.show = true
      }
    }
  }
</script>
<style lang="css" scoped>
</style>
