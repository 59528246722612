<template>
  <div class="MapSelectedPoint">
    <LeafletMap ref="map" @MapReady="onMapReady" :mapConfig="mapConfig" :ToolBarsConfig="ToolBarsConfig" :currentItem="currentItem" :currentLoc="currentLoc" :mapCenter="currentLoc" :currentItemConfig="currentItemConfig"
                :ToolBars="ToolBars" :baseMaps="baseMaps" :zoom="zoom" :autoZoom="false" :layers="layers" :layer="layer" :url="url" :params="params"></LeafletMap>
    <div style="position:absolute;bottom:30px;right:10px;z-index:1080;">
      <button v-if="location&&!(location.lat == 0 && location.lng == 0)" class="btn btn-primary btn-sm mr-1" @click="backToSelected">回到选择点</button>
      <button class="btn btn-sm btn-primary mr-1" @click="resetLocation">重置选择点</button>
      <button class="btn btn-sm btn-primary" @click="submit">确认地点</button>
      <slot name="buttons"></slot>
    </div>
    <div id="`searchResult_${randid}`"></div>
  </div>
</template>
<script>
  import LeafletMap from './PanoLeafletMap'
  export default {
    scrollToTop: true,
    components: {
      LeafletMap,
    },
    props: {
      mapConfig: {
        default: () => {
          return {
            zoom: 10,
          }
        }
      },
      markerPoint: Object,
      ToolBarsConfig: {},
      currentItem: {},
      currentLoc: {},
      currentPanoView: {},
      currentItemConfig: {},
      ToolBars: {},
      baseMaps: {},
      zoom: {},
      layers: {},
      layer: {},
      url: {},
      params: {},
      //citycode: Number,
    },
    data: () => {
      return {
        randid: (Math.random() * 10000).toFixed(0),
        location: null,
        //aMap: null,
        notchangemap: null,
        currentMarkEl: null,
      }
    },
    computed: {

    },
    created() {
      //if (!(this.markerPoint.lat == 0 && this.markerPoint.lng == 0)) {
      //    this.notchangemap = true
      //} else {
      //    this.notchangemap = !!this.citycode
      //}
    },
    mounted() {
    },
    watch: {
      currentPanoView(val, oldval) {
        if (this.currentMarkEl) {
          this.updatePanoViewPie(this.currentMarkEl, val)
        }
      }
    },
    methods: {
      submit() {
        this.location = { lat: this.math360(this.location.lat, 0), lng: this.math360(this.location.lng, 0) }
        this.$emit('submit', this.location)
      },
      renewMark() {
        var p = { lat: this.location.lat, lng: this.location.lng }
        var icon
        if (this.currentPanoView) {
          this.currentMarkEl = this.drawPanoViewPie(this.currentPanoView, 110, 110)
          icon = L.divIcon({
            html: this.currentMarkEl,
            iconSize: [110, 110],
            className: 'svgicon'
            //  iconAnchor: [9, 30],
            //  popupAnchor: [0, -30],
          })
        }
        this.$refs.map.$refs.map.addMark('markpoint', {
          draggable: true,
          position: p,
          icon: icon,
          on: {
            dragend: (event) => {
              this.location = this.$refs.map.$refs.map.getMarkLatLng(event.target)
            }
          }
        })
      },
      resetLocation() {
        if (this.markerPoint && !(this.markerPoint.lat == 0 && this.markerPoint.lng == 0)) {
          this.location = this.markerPoint
        } else {
          this.location = null
        }
        if (this.location) {
          this.renewMark()
          this.$refs.map.$refs.map.setCenterLatLng(this.location.lat, this.location.lng)
        } else {
          this.$refs.map.$refs.map.removeMark('markpoint')
          //this.mapSetCity(this.citycode)
        }
      },
      getCurrentPosition() {
        this.$refs.map.$refs.map.getlocation({ panToLocation: false }, (data) => {
          if (this.notchangemap) {
            this.notchangemap = false;
            return
          }
          var p = { lat: data.lat, lng: data.lng }
          //this.$refs.map.$refs.map.setCenterLatLng(p.lat, p.lng)
        }).then((data) => {
          //this.$refs.map.$refs.map.setCenterLatLng(p.lat,p.lng )
          //this.setMark()
          //console.log(data)
        })
      },
      onMapReady(aMap) {
        this.getCurrentPosition()
        this.$refs.map.$refs.map.addMapEvent('touchstart', (event) => {
          this.touchstart = { event: event, time: Date.now() }
        })
        this.$refs.map.$refs.map.addMapEvent('touchend', (event) => {
          if (this.touchstart.time - Date.now() < 300) {
            var x1 = this.touchstart.event.pixel.x
            var y1 = this.touchstart.event.pixel.y
            var x2 = event.pixel.x
            var y2 = event.pixel.y
            if (((x1 - x2) * (x1 - x2) + (y1 - y2) * (y1 - y2)) < 100) {
              this.onClick(event)
            }
          }
        })
        this.$refs.map.$refs.map.addMapEvent('click', (event) => {
          this.onClick(event)
        })
        //this.aMap = aMap
        this.resetLocation()
        L.DomEvent.on(this.$refs.map.$refs.map.leafletMap, 'baselayerchange', (e) => {
          this.$nextTick((e) => {
            if (this.location) {
              this.renewMark()
            } else {
              this.$refs.map.$refs.map.removeMark('markpoint')
            }
          })
        }, this)
      },
      onClick(event) {
        console.log(event)
        var p = { lat: event.latlng.lat, lng: event.latlng.lng }
        this.location = p
        this.setMark()
      },
      setMark() {
        if (this.location) {
          this.renewMark()
          var p = { lat: this.location.lat, lng: this.location.lng }
          this.$refs.map.$refs.map.setCenterLatLng(p.lat, p.lng)
        }
      },
      backToSelected() {
        var p = { lat: this.location.lat, lng: this.location.lng }
        this.$refs.map.$refs.map.setCenterLatLng(p.lat, p.lng)
      },
      drawPanoViewPie(view, w, h) {
        var el = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        el.setAttribute("width", w);
        el.setAttribute("height", h);
        //el.style.position = 'absolute'
        //el.style.left = `${-w / 2}px`
        //el.style.top = `${-h / 2}px`

        var path = document.createElementNS("http://www.w3.org/2000/svg", "path");
        el.appendChild(path);
        path.setAttribute("stroke", '#81814c');
        path.setAttribute("stroke-width", 1);
        path.setAttribute("stroke-opacity", 0.75);
        path.setAttribute("fill", '#e5e5b8');
        path.setAttribute("fill-opacity", 0.5)

        this.updatePanoViewPie(el, view);
        return el
      },
      updatePanoViewPie(el, view) {
        if ((view?.fov ?? view?.hLookAt) == null) {
          return
        }
        var w = Number(el.getAttribute('width'))
        var h = Number(el.getAttribute('height'))
        var path = el.getElementsByTagName('path')[0]

        var gpsImgDirection = this.currentItem?.gpsImgDirection ?? 0
        var hl = this.math360(Number(view.hLookAt)) - gpsImgDirection
        var s = hl - view.fov / 2
        var e = hl + view.fov / 2
        var d = this.drawpie(w / 2, h / 2, Math.min(w, h) / 2 - 10, s, e)
        path.setAttribute("d", d)
        return el
      },
      math360(i, n) {
        //n ??= 180
        if (n == undefined) {
          n = 180
        }
        while (i < n - 180 || i >= n + 180) {
          i = i > n ? i - 360 : i + 360
          i = Number(Number(i).toFixed(4))
        }
        return i
      },
      drawpie(//绘制扇形svg路径d
        /*x轴坐标*/  x,
        /*y轴坐标*/ y,
        /*半径*/ r,
        /*起始角度*/ sa,
        /*终点角度*/ ea
      ) {
        var g, f;
        sa > ea && (g = ea, ea = sa, sa = g);
        sa = sa * Math.PI / 180;
        ea = ea * Math.PI / 180;
        f = ea - sa;
        g = (sa + ea) / 2;
        var h = f > Math.PI ? 1 : 0;
        f >= 2 * Math.PI && (f = 2 * Math.PI - .01);
        sa = g - f / 2; ea = g + f / 2;
        g = x + r * Math.sin(sa);
        sa = y - r * Math.cos(sa);
        f = x + r * Math.sin(ea);
        ea = y - r * Math.cos(ea);
        return `M ${x},${y} L  ${g},${sa} A  ${r},${r} 0 ${h} 1 ${f},${ea} Z`
        //return "M " + x + "," + y + " L " + g + "," + sa + " A " + r + "," + r + " 0 " + h + " 1 " + f + "," + ea + " Z"
      },

      //search(txt) {
      //  AMap.service(["AMap.PlaceSearch"], function () {
      //    //构造地点查询类
      //    var placeSearch = new AMap.PlaceSearch({
      //      pageSize: 5, // 单页显示结果条数
      //      pageIndex: 1, // 页码
      //      //city: "010", // 兴趣点城市
      //      citylimit: true,  //是否强制限制在设置的城市内搜索
      //      map: map, // 展现结果的地图实例
      //      panel: `searchResult_${this.randid}`, // 结果列表将在此容器中进行展示。
      //      autoFitView: true // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
      //    });
      //    //关键字查询
      //    placeSearch.search(txt);
      //  });
      //},
      //mapSetCity(cityCode) {
      //  console.log('mapSetCity')
      //  var pcode = Math.floor(cityCode / 10000)
      //  switch (pcode) {
      //    case 11:
      //    case 12:
      //    case 31:
      //    case 50:
      //      break;
      //    default:
      //      if ((cityCode % 100) == 1) {
      //        cityCode--;
      //      }
      //      break;
      //  }
      //  //console.log('地图' + cityCode);
      //  if (this.aMap && cityCode) {
      //    this.aMap.setCity(cityCode);
      //    console.log('地图定位到' + cityCode);
      //  }
      //},
    }
  }
</script>
<style lang="css" scoped>
  .MapSelectedPoint {
    width: 100%;
    height: 100%;
    position: relative;
  }
</style>
